addEventListener("load", (event) => {
	// Footer copyright year
	const footer = document.querySelector(".footer");

	if (footer) {
		const finePrint = footer.querySelector(".fine-print");
		const year = finePrint.querySelector(".year");
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();

		year.innerText = currentYear;
	}
});
